
<template>
  <div
    class="homeItem_container"
    style="border-radius: 10px"
    @click.stop="clearInviteBanner"
  >
    <div class="homeItem_status_block" v-if="getStatusText()">
      <div
        class="homeItem_status colorRed"
        :class="{
          homeItem_status_green: data.audit_status == 20 && data.meet_type == 0,
        }"
      >
        <span>{{ getStatusText() }}</span>
      </div>
    </div>
    <!-- <div class="media" @click.stop="goToDetail">
      <div class="media_body">
        <div class="media_body_name">
          <div>
            {{ this.data.user_name || "姓名" }}
          </div>
        </div>
        <div class="media_body_hospital">
          <span class="text-muted">发表于 {{ this.data.create_time }}</span>
        </div>
      </div>
    </div> -->
    <div class="title" @click.stop="goToDetail">
      {{ this.data.title }}
    </div>
    <div class="info" @click.stop="goToDetail">
      {{ this.data.content }}
    </div>
    <div class="speaker_all" ref="speaker">
      <div
        :class="getBoxClass(doctorInfo, doctorIndex)"
        v-for="(doctorInfo, doctorIndex) in getRoles()"
        :key="doctorIndex"
      >
        <div class="speaker_box_item">
          <div>
            <div class="speaker_box_item_name">
              <span class="colorRed"
                >{{ $config.types.roleMap[doctorInfo.role] }}
                {{
                  $config.types.roleMap[doctorInfo.role] == "会议讲者"
                    ? "（发帖人）"
                    : ""
                }}</span
              >

              <!-- 占位 -->
              <span
                class="speaker_box_item_hospital_text"
                v-if="
                  doctorIndex == 4 &&
                  getDoctorName(doctorInfo, doctorIndex) == '暂缺'
                "
              >
                （非必须）
              </span>
            </div>
            <div
              class="speaker_box_item_name_text"
              v-if="isShowName(doctorInfo, doctorIndex)"
              :class="{ speaker_box_item_hosptial: !doctorInfo.id }"
            >
              {{ getDoctorName(doctorInfo, doctorIndex) }}
            </div>
            <div
              class="speaker_box_item_name_text speaker_box_item_name_text_single"
              v-if="!isShowName(doctorInfo, doctorIndex)"
              :class="{ speaker_box_item_hosptial: !doctorInfo.id }"
            >
              {{ getDoctorName(doctorInfo, doctorIndex) }}
            </div>
            <div
              class="speaker_box_item_hospital_text"
              v-if="doctorInfo.hospital"
            >
              {{ doctorInfo.hospital }}
            </div>
          </div>
          <div class="speaker_box_item_hosptial">
            <div v-if="!doctorInfo.id">
              <!-- 如果没有专家，我是讲者，我就可以邀请和变更专家 -->
              <div v-if="isMyMeet()">
                <!-- {{ findInviteDoctor(doctorInfo) }} -->
                <div
                  v-if="findInviteDoctor(doctorInfo)"
                  class="speaker_go_meeting"
                  :ref="`invite${doctorIndex}`"
                  @click.stop="
                    invitePerson($event, doctorInfo, doctorIndex, 'update')
                  "
                >
                  变更
                </div>

                <div
                  v-else-if="
                    (isMyMeet() && data.post_type == 1) ||
                    (isMyMeet() &&
                      data.post_type == 0 &&
                      data.audit_status >= 40)
                  "
                  :ref="`invite${doctorIndex}`"
                  class="speaker_go_meeting"
                  @click.stop="
                    invitePerson($event, doctorInfo, doctorIndex, 'add')
                  "
                >
                  邀请
                </div>
              </div>
              <!-- 反之我不是讲者,我可以入会,我在里面 -->
              <div
                v-else-if="
                  findInviteDoctorZX(doctorInfo.role, doctorInfo.index)
                "
                class="home-item-invite-group"
              >
                <div
                  v-if="doctorInfo.role == this.$config.types.roles.zx"
                  class="speaker_go_meeting bgGreen"
                  @click.stop="agreeInvite(doctorInfo.role, doctorInfo.index)"
                >
                  同意邀请
                </div>
                <div
                  v-else-if="doctorInfo.role == this.$config.types.roles.tl"
                  class="speaker_go_meeting bgGreen"
                  @click.stop="geToMeet(doctorInfo.role, doctorInfo.index)"
                >
                  同意并回帖
                </div>
                <div
                  class="speaker_go_meeting"
                  @click.stop="doRefuse(doctorInfo.role, doctorInfo.index)"
                >
                  拒绝
                </div>
              </div>
              <!-- 反之我不是讲者,我可以入会 -->
              <div
                v-else-if="
                  !isInMeeting() &&
                  isInRole(doctorInfo.role) &&
                  mode == 0 &&
                  !isInInviteList() &&
                  data.audit_status < 40
                "
                class="speaker_go_meeting"
                @click.stop="geToMeet(doctorInfo.role, doctorInfo.index)"
                :class="
                  !isInRoleNumber(isInRole(doctorInfo.role))
                    ? 'speaker_go_meeting_disabled'
                    : ''
                "
              >
                我要入会
              </div>

              <div
                v-else-if="data.meet_type == 0"
                class="speaker_go_meeting global_hidden"
              ></div>
              <div v-else class="speaker_go_meeting global_hidden"></div>
            </div>
            <div v-else>
              <!-- 如果医生存在,我是讲者,我可以更换专家 -->
              <div
                class="colorRed"
                v-if="isMyMeet() && doctorInfo.accept_join == 2"
              >
                退出确认中
              </div>

              <div
                v-else-if="
                  isMyMeet() &&
                  doctorInfo.id != $store.state.common.userId &&
                  data.tx_meet_status == 0
                "
                :ref="`invite${doctorIndex}`"
                class="speaker_go_meeting"
                @click.stop="
                  replaceDoctor($event, doctorInfo, doctorIndex, 'update')
                "
              >
                <!-- invitePerson($event, doctorInfo, doctorIndex, 'update') -->
                变更
              </div>
              <div
                class="home-item-invite-group"
                v-else-if="
                  !isMyMeet() &&
                  doctorInfo.id == $store.state.common.userId &&
                  doctorInfo.accept_join == 2 &&
                  mode == 1
                "
              >
                <div
                  class="speaker_go_meeting bgGreen"
                  @click.stop="agreeExit($event, doctorInfo, doctorInfo.index)"
                >
                  同意退会
                </div>
                <div
                  class="speaker_go_meeting"
                  @click.stop="refuseExit($event, doctorInfo, doctorInfo.index)"
                >
                  拒绝
                </div>
              </div>
              <div
                v-else-if="
                  !isMyMeet() &&
                  doctorInfo.id == $store.state.common.userId &&
                  data.audit_status < 40 &&
                  mode == 1
                "
                class="speaker_go_meeting"
                @click.stop="exitMeeting($event, doctorInfo, doctorInfo.index)"
              >
                退出会议
              </div>
              <div
                v-else-if="data.meet_type == 0"
                class="speaker_go_meeting global_hidden"
              ></div>
            </div>

            <!-- <div   class="speaker_go_meeting" @click.stop="invitePerson($event,doctorIndex)" v-if="doctorInfo.role != this.$config.types.roles.jz" >更换</div> -->
            <div
              class="invite-person-main"
              :class="`invite-person-tag-${doctorIndex % 2}`"
              @click.stop=""
              :style="`${inviteStyle};`"
              v-if="inviteBanner[doctorIndex]"
            >
              <div class="global_text_input invite-person-select">
                <el-select
                  v-model="doctor"
                  filterable
                  remote
                  @change="changeDoctor"
                  placeholder="请输入专家姓名"
                  remote-show-suffix
                  :remote-method="
                    (query) => {
                      loadDoctors(query, doctorInfo);
                    }
                  "
                  :loading="loading"
                >
                  <el-option
                    v-for="currDoctor in config.doctors"
                    :key="currDoctor.id"
                    :label="currDoctor.showName"
                    :value="currDoctor.id"
                  />
                </el-select>
              </div>
              <div class="invite-person-tip">
                注意:邀请后需专家同意后,才算邀请成功
              </div>
              <div class="invite-person-button global_container_center">
                <div
                  size="small"
                  class="speaker_go_meeting"
                  @click.stop="inviteDoctor(doctorInfo)"
                >
                  确认邀请
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="meet_attachments">
      <div class="colorRed meet_key"> 附件：</div> {{ this.data.file_attachments_url.split("/").pop() }} 
      
      <div class="speaker_go_meeting"  @click.stop="invitePerson($event,doctorIndex)">修改</div>
    </div> -->
    <!-- audit_status   40 课件审核中 50待召开 60课件审核中/失败-->
    <!-- meet_type 0 组队中， 10组队完成 20会议完成 30超时未组满  -->
    <div class="meet_date">
      <span class="colorRed" style="font-weight: bold">
        {{ this.data.meet_type == 0 ? "预计" : "" }}会议开始时间：</span
      >
      <!-- 公开征集会议到预定时间还未组队完成的   -->
      <!-- <span
        v-if="
          data.meet_type == 0 &&
          data.post_type == 0 &&
          this.$tools.getTime(this.data.meet_start_time) < this.$tools.getTime()
        "
        >时间待组队后商议
      </span>
      <span v-else style="font-weight: bold">{{
        this.data.meet_type == 30
          ? "暂无"
          : this.data.audit_status == 40 &&
            this.$tools.getTime(this.data.meet_start_time) <
              this.$tools.getTime()
          ? "暂无"
          : this.data.meet_start_time?.substr(0, 16)
      }}</span> -->

      <!-- 预计会议开始时间后面的文字改为：会议预定时间已过，请讲者点击信息修改重新预约会议时间 -->
      <span v-if="data.post_type == 0"
        >{{
          this.data.tx_meet_status == 0 &&
          this.$tools.getTime(this.data.meet_start_time) < this.$tools.getTime()
            ? "会议预定时间已过，请讲者点击信息修改重新预约会议时间"
            : this.data.meet_start_time
        }}
      </span>
      <!-- 定向 -->
      <span v-if="data.post_type == 1" style="font-weight: bold">{{
        this.data.meet_type == 30
          ? "暂无"
          : this.data.audit_status == 40 &&
            this.$tools.getTime(this.data.meet_start_time) <
              this.$tools.getTime()
          ? "暂无"
          : this.data.meet_start_time?.substr(0, 16)
      }}</span>
    </div>
    <!-- 会前沟通会时间： -->
    <div class="meet_date" v-if="this.data.meet_type == 10 && false">
      <span class="colorRed"> 会前沟通会时间：</span>
      {{
        this.data.audit_status == 40 &&
        this.$tools.getTime(this.data.meet_start_time) < this.$tools.getTime()
          ? "暂无"
          : this.data.meet_test_time?.substr(0, 16)
      }}
      <!-- {{ this.data.meet_test_time?.substr(0, 16) }} -->
    </div>
    <div class="read">
      <span style="width: 10rem"
        >{{ this.data.read_count || 0 }} 浏览・
        {{ this.data.reply_count || 0 }} 讨论</span
      >
      <!-- <span>{{ this.data.collect_total || 0 }} 点赞</span> -->
      <div class="disease_title_block disease_title_type colorRed">
        <div class="disease_title">
          <!-- <img
          src="https://guzhuanbaixun.ecloudmt.com/assets/images/icon-disease/%E8%82%9D.png"
          alt=""
        /> -->
          <span>{{ this.data.diseases_top_type }}</span>
        </div>
      </div>
    </div>

    <div
      class="disease_title_block"
      style="color: red"
      v-if="this.data.meet_type == 30"
    >
      您预定的会议时间已过期，请点击信息修改按钮进行重新选择
    </div>
    <div
      class="disease_title_block"
      style="color: red"
      v-if="
        mode == 1 &&
        (this.data.audit_status == 30 || this.data.audit_status == 60) &&
        this.isMyMeet()
      "
    >
      {{ data.audit_reason }}
    </div>
    <!-- 会议审核失败-截图审核失败 -->
    <div
      class="disease_title_block"
      style="color: red"
      v-if="mode == 1 && this.data.doctor_audit_status == 2"
    >
      {{ data.doctor_audit_reason }}
    </div>
    <!--劳务审核失败 -->
    <div
      class="disease_title_block"
      style="color: red"
      v-if="mode == 1 && this.data.doctor_labor_audit_status == 2"
    >
      {{ data.doctor_labor_audit_reason }}
    </div>

    <!-- 我的会议 组建成功显示 -->
    <div
      class="disease_title_block disease_groupbtn"
      ref="disease_groupbtn"
      :class="getChildEle() ? 'disease_groupbtn_end' : ''"
      v-if="mode == 1"
    >
      <div
        class="disease_status"
        v-if="
          data.user_id == $store.state.common.userInfo.id &&
          mode == 1 &&
          data.meet_type != 20
        "
        @click.stop="goToUpdate"
        :class="{ bgGreen: data.audit_status == 20 && data.meet_type == 10 }"
      >
        {{
          data.tx_meet_status == 0 &&
          data.audit_status == 20 &&
          data.meet_type == 10
            ? "请点击并确认会议信息"
            : "信息修改"
        }}
      </div>
      <!-- /tx_meet_status -->

      <div
        style="background: #ccc"
        class="disease_status"
        @click.stop="
          goToAgreement(
            data,
            data.doctor_labor_audit_status,
            data.doctor_audit_status
          )
        "
        v-if="
          data.tx_meet_status == 20 &&
          (data.doctor_labor_audit_status == 0 ||
            data.doctor_labor_audit_status == 2) &&
          !data.sign_status
        "
      >
        {{
          data.doctor_audit_status == 2
            ? "会议审核未通过"
            : data.doctor_labor_audit_status == 0
            ? "劳务协议准备中"
            : "会议审核未通过"
        }}
      </div>
      <div
        class="disease_status"
        @click.stop="goToAgreement(data, data.doctor_labor_audit_status)"
        v-if="data.doctor_labor_audit_status == 1 && !data.sign_status"
      >
        点击签署劳务协议
      </div>

      <div
        class="disease_status"
        v-if="data.tx_meet_status == 20 && data.sign_status"
        @click="payStatsu(data, data.pay_status)"
      >
        我的支付状态
      </div>
      <div
        v-if="false"
        class="disease_status"
        @click="participateSelection(data)"
        :style="{ background: data.is_selection == 1 ? '#ccc' : '#22b783' }"
      >
        {{ data.is_selection ? "评选已报名" : "参与评选" }}
      </div>
      <div
        class="disease_status"
        v-if="data.post_type == 1 && isMyMeet() && data.meet_type == 0"
        @click.stop="doUpdateMeetType"
      >
        转为公开征集
      </div>
      <el-badge
        :hidden="data.wait_read == 0"
        :value="data.wait_read"
        :max="99"
        :class="data.wait_read == 0 ? '' : 'item'"
        class="chat_btn"
        v-if="this.data.post_tag == 20"
        type="primary"
      >
        <div class="disease_status" @click.stop="goToChat">详情、物料&群聊</div>
        <!-- 同时帖子发布成功之后，就要展示【详情、物料&群聊】按钮 -->
      </el-badge>
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import PopopInstance from "@/components/unit/PopupInstance";
export default {
  name: "homeItem",
  props: {
    item: {
      type: Object,
      default: {},
    },
    // 0 自由市场,1 我的会议 2 邀请
    mode: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
    },
  },
  components: {
    CustomButton,
  },
  data() {
    return {
      config: {
        weeks: [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ],
        doctors: [],
        msg: {
          not: {
            title: "温馨提示",
            html: `<div class="popup-content-main">您暂未完善个人信息，请先点击【去完善】完善您的个人信息</div>`,
            btngroup: { sure: "去完善", close: "暂不参会" },
            align: "center",
          },

          have: {
            title: "个人信息已完善",
            content: "",
            btngroup: { sure: "确认", close: "取消" },
            align: "center",
          },
          audit: {
            title: "温馨提示",
            html: `<div class="popup-content-main">提交审核后将会安排日程，您确认吗？</div>`,
            btngroup: { sure: "确认", close: "取消" },
            align: "center",
          },
          refuse: {
            title: "温馨提示",
            html: `<div class="popup-content-main">拒绝邀请后将不能更改为同意邀请，请确认</div>`,
            btngroup: { sure: "确认", close: "取消" },
            align: "center",
          },
          mode: {
            title: "温馨提示",
            html: `<div class="popup-content-main">转为公开征集后，已邀请未同意的专家位将被释放，系统中所有符合条件的专家均可匹配该贴，请确认是否转为公开征集？ \n<span class="gloabl_hilight_red"> 注：转为公开征集后，无法再转回指定邀请，请知悉。</span>
            </div>`,
            btngroup: { sure: "确认", close: "取消" },
            align: "center",
          },
          exit: {
            title: "温馨提示",
            html: `<div class="popup-content-main">您即将退出本场会议，退出后系统将返还您的权益，请您再次确认。</div>`,
            btngroup: { sure: "确认", close: "取消" },
            align: "center",
          },
          update: {
            title: "温馨提示",
            html: `<div class="popup-content-main">
              请确认是否变更专家，点击确认后，请邀请新的专家。\n<span class="gloabl_hilight_red">注：需当前专家同意退出，系统才会邀请新的专家，若当前专家未回复或拒绝退出，该会议仍由当前专家参会</span>
              </div>`,
            btngroup: { sure: "确认", close: "取消" },
            align: "center",
          },
          agreeExit: {
            title: "温馨提示",
            html: `<div class="popup-content-main">
              请您确认是否退出该贴。若确认，您的权益将恢复，您可以参与其他您感兴趣的帖子
              </div>`,
            btngroup: { sure: "确认", close: "取消" },
            align: "center",
          },
          refuseExit: {
            title: "温馨提示",
            html: `<div class="popup-content-main">
             请您确认是否拒绝退出该会议，若确认拒绝，您将继续参与该贴会议，请注意参会时间，按时参会哦
              </div>`,
            btngroup: { sure: "确认", close: "取消" },
            align: "center",
          },
        },
      },
      data: {},
      tempRoles: [],
      inviteRoles: [],
      tempInviteList: [],
      inviteBanner: [],
      inviteStyle: 0,
      doctor: "",
      loading: false,
      showPopup: false,
      popupInfo: {},
      clientWidth: 0,
      inviteWidth: 0,
      popupKey: "",
      requestLoading: false,
      inviteType: "",
      childElements: [],
    };
  },
  created() {
    this.data = { ...this.item };
    this.$nextTick(() => {
      this.clientWidth = document.body.clientWidth;
      let speakerNode = this.$refs.speaker.getBoundingClientRect();
      this.inviteWidth = speakerNode.width;
      this.inviteStyle = `width:calc(${this.inviteWidth}px - 1rem);left:0`;
    });
    this.initInviteRoles();
  },
  mounted() {
    this.childElements = this.$refs.disease_groupbtn?.children;
  },
  methods: {
    getChildEle() {
      if (
        this.childElements?.length == 1 &&
        this.childElements[0].className.includes("chat_btn")
      )
        return true;
      else return false;
    },
    initInviteRoles() {
      let persons = this.data.no_join || {};
      let inviteRoles = [];
      for (let key in persons) {
        inviteRoles = inviteRoles.concat(
          persons[key].map((person) => {
            person.role = key;
            return person;
          })
        );
      }
      this.inviteRoles = inviteRoles;
    },
    getRoles() {
      let defaultZhuxi = this.data[this.$config.types.roles.zx] || [];
      defaultZhuxi = defaultZhuxi.concat(
        this.tempRoles[this.$config.types.roles.zx] || []
      );
      let zhuxi = defaultZhuxi
        .map((person) => {
          person.role = this.$config.types.roles.zx;
          person.index = person.index || 1;
          return person;
        })
        .sort((x, y) => (x.index > y.index ? 1 : -1));
      let zhuxis = [];
      for (let index in new Array(this.data.can_role_zhuxi).fill(1)) {
        let person = zhuxi.find((person) => person.index == Number(index) + 1);
        zhuxis.push(
          person || {
            role: this.$config.types.roles.zx,
            index: Number(index) + 1,
          }
        );
      }

      let defaultJiangZhe = this.data[this.$config.types.roles.jz] || [];
      defaultJiangZhe = defaultJiangZhe.concat(
        this.tempRoles[this.$config.types.roles.jz] || []
      );
      let jiangzhe = defaultJiangZhe
        .map((person) => {
          person.role = this.$config.types.roles.jz;
          person.index = person.index || 1;
          return person;
        })
        .sort((x, y) => (x.index > y.index ? 1 : -1));
      let jiangzhes = [];
      for (let index in new Array(this.data.can_role_jiangzhe).fill(1)) {
        let person = jiangzhe.find(
          (person) => person.index == Number(index) + 1
        );
        jiangzhes.push(
          person || {
            role: this.$config.types.roles.jz,
            index: Number(index) + 1,
          }
        );
      }

      let defaultTaoLun = this.data[this.$config.types.roles.tl] || [];
      defaultTaoLun = defaultTaoLun.concat(
        this.tempRoles[this.$config.types.roles.tl] || []
      );
      let taolun = defaultTaoLun
        .map((person) => {
          person.role = this.$config.types.roles.tl;
          person.index = person.index || 1;
          return person;
        })
        .sort((x, y) => (x.index > y.index ? 1 : -1));
      let taoluns = [];
      let tlTimes = this.getTlTimes();
      for (let index in new Array(tlTimes).fill(1)) {
        let person = taolun.find((person) => person.index == Number(index) + 1);
        taoluns.push(
          person || {
            role: this.$config.types.roles.tl,
            index: Number(index) + 1,
          }
        );
      }
      return [...zhuxis, ...jiangzhes, ...taoluns];
    },
    getAllRoles() {
      let result = [];
      for (let key in this.$config.types.roles) {
        result = result.concat(this.data[this.$config.types.roles[key]]);
      }
      return result;
    },
    getTlTimes() {
      let taolun3Item = this.data[this.$config.types.roles.tl].find(
        (x) => x.index == 3
      );
      let invateTaolun3Item = (this.data?.no_join?.ROLE_TAOLUN || []).find(
        (x) => x.index == 3
      );
      let isHaveIndex3 = taolun3Item || invateTaolun3Item || false;
      if (this.mode == 1)
        return this.data.tx_meet_status == 20
          ? this.data[this.$config.types.roles.tl].length
          : isHaveIndex3
          ? 3
          : this.data.can_role_taolun;
      // if(this.data.)
      if (this.isCompleteToNotComplete()) return this.data.can_role_taolun;
      // return this.data.audit_status >= 40
      //   ? this.data[this.$config.types.roles.tl].length
      //   : isHaveIndex3 ? 3 : this.data.can_role_taolun;
      return isHaveIndex3
        ? 3
        : this.data.audit_status >= 40
        ? this.data[this.$config.types.roles.tl].length
        : this.data.can_role_taolun;
    },
    isCompleteToNotComplete() {
      return (
        this.data.audit_status > 40 &&
        this.inviteRoles.length > 0 &&
        this.getAllRoles().length < 4
      );
    },
    loadDoctors(query = "", doctorInfo) {
      if (query == "") return;
      let url = this.$tools.getURL(this.$urls.user.list, {
        post_id: this.data.id,
        filter_text: query,
        filter_role: doctorInfo.role,
        filter_ids: this.getIdList().join(","),
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.doctors = [...res.data].map((doctor) => {
            doctor.showName = `${doctor.name}-${doctor.hospital || "暂无"}`;
            return doctor;
          });
        })
        .catch(() => {});
    },
    isShowName(doctorInfo, doctorIndex) {
      if (this.isFive() && doctorIndex > 1) {
        if (doctorInfo.id) return false;
        let doctor = this.findInviteDoctor(doctorInfo);

        return !doctor ? true : false;
      }
      return true;
    },
    getIdList() {
      let persons = this.getRoles().map((x) => x.id);
      let invites = this.inviteRoles.map((x) => x.id);
      return [...new Set([...persons, ...invites])].filter((x) => x);
    },
    getDoctorName(doctorInfo, doctorIndex) {
      if (doctorInfo.id) return doctorInfo.name;
      let doctor = this.findInviteDoctor(doctorInfo);
      return doctor ? `${doctor.name}(邀请中)` : "暂缺";
    },

    getStatusText() {
      if (this.data.meet_timeout == 1) return "超时未召开";
      if (this.data.post_tag == 10) return "待完善";
      if (this.data.pay_status == 1) return `已完成`; //已支付
      if (this.data.pay_status == 2) return "已完成"; //支付失败
      if (this.data.tx_meet_status == 10) return "进行中";
      if (this.data.tx_meet_status == 20) {
        // if (this.data.sign_status) return "待支付";
        // if (this.data.doctor_labor_audit_status == 1) return "点击签署劳务协议";
        // if (this.data.doctor_labor_audit_status == 2) return "会议审核未通过";
        // if (this.data.doctor_labor_audit_status == 0) return "劳务协议准备中";
        return "已完成"; //待支付
      } else {
        try {
          if (this.isCompleteToNotComplete()) return "待召开人员变更中";
        } catch (err) {
          console.log(err);
        }
        if (this.data.audit_status == 50) return "待召开";
        if (this.data.audit_status == 40) return "课件审核中";
        if (this.data.audit_status == 60)
          return this.isMyMeet() ? "课件审核失败" : "课件审核中";
        if (this.data.meet_type == 10) return "等待讲者确认会议时间";
        if (this.data.meet_type == 0) return "组建中";
      }
      return "";
    },
    isInMeeting() {
      let roles = this.getRoles();
      return roles.find((role) => role.id == this.$store.state.common.userId);
    },
    // 是否是我创建的会议
    isMyMeet() {
      return this.data.user_id == this.$store.state.common.userId;
    },
    isNotMyMeet() {
      return this.data.user_id != this.$store.state.common.userId;
    },
    findInviteDoctor(doctorInfo) {
      return this.inviteRoles.find(
        (person) =>
          person.role == doctorInfo.role && person.index == doctorInfo.index
      );
    },
    findInviteDoctorZX(role, index) {
      return (this.inviteRoles || "").find(
        (person) =>
          person.role == role &&
          person.id == this.$store.state.common.userInfo.id &&
          person.index == index
      );
    },
    isInInviteList() {
      return this.inviteRoles.find(
        (person) => person.id == this.$store.state.common.userInfo.id
      );
    },
    isInRole(code) {
      return (this.$store.state.common.userInfo.roles || []).find(
        (role) => role.role_code == code
      );
    },
    //参会次数
    isInRoleNumber(code) {
      let role = code.role_code;
      if (role == "ROLE_ZHUXI") {
        return (
          this.$store.state.common.userInfo.cohosts_count -
            this.$store.state.common.userInfo.join_cohosts_count >
          0
        );
      } else if (role == "ROLE_TAOLUN") {
        return (
          this.$store.state.common.userInfo.guest_count -
            this.$store.state.common.userInfo.join_guest_count >
          0
        );
      }
    },
    goToDetail() {
      if (this.data.post_tag == 10)
        return this.$tips.error({
          text: "帖子待完善,请点击信息修改完善帖子信息",
        });
      if (this.mode == 2) return;
      this.$router.push({ path: "/detail", query: { id: this.data.id } });
    },
    replaceDoctor(event, doctorInfo, doctorIndex) {
      PopopInstance(this.config.msg.update)
        .then((res) => {
          this.invitePerson(event, doctorInfo, doctorIndex, "replace");
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    },
    agreeExit(event, doctorInfo, doctorIndex) {
      PopopInstance(this.config.msg.agreeExit)
        .then((res) => {
          this.$axios
            .post(this.$urls.live.agreeExit, {
              id: this.data.id,
              user_id: doctorInfo.id,
              status: 1,
            })
            .then(() => {
              this.updataExitDoctor(doctorInfo);
              this.initInviteRoles();
              this.$tips.success({ text: "退出成功" });
            });
        })
        .catch(() => {});
    },
    updataExitDoctor(doctorInfo) {
      let rolePersonIndex = (this.data[doctorInfo.role] || []).findIndex(
        (person) => person.index == doctorInfo.index
      );
      let doctor = this.data["ROLE_TAOLUN_INVITE"].find((v) => {
        return v.role_code == doctorInfo.role && v.index == doctorInfo.index;
      });
      this.data[doctorInfo.role].splice(rolePersonIndex, 1);
      this.data.no_join[doctorInfo.role].push({
        ...doctor,
        accept_join: 0,
      });
    },
    refuseExit(event, doctorInfo, doctorIndex) {
      PopopInstance(this.config.msg.refuseExit)
        .then((res) => {
          this.$axios
            .post(this.$urls.live.refuseExit, {
              id: this.data.id,
              user_id: doctorInfo.id,
              status: 2,
            })
            .then(() => {
              this.updataDoctorExitStatus(doctorInfo.role, doctorInfo.index, 1);
              this.$tips.success({ text: "拒绝成功" });
            });
        })
        .catch(() => {});
    },
    exitMeeting(event, doctorInfo, doctorIndex) {
      PopopInstance(this.config.msg.exit)
        .then((res) => {
          if (this.requestLoading) return;
          this.requestLoading = true;
          let url = this.$tools.getURL(this.$urls.live.exit, {});
          let data = {
            post_id: this.item.id,
            user_id: doctorInfo.id,
          };
          this.$axios
            .post(url, data)
            .then((res) => {
              setTimeout(() => {
                this.requestLoading = false;
              }, 1000);
              this.data.meet_type = 0;
              this.deleteAlreadyDoctor(doctorInfo.role, doctorIndex);
              this.$tips.success({ text: "退出成功" });
            })
            .catch((err) => {
              setTimeout(() => {
                this.requestLoading = false;
              }, 1000);
              console.log(err);
              this.$tips.error({ text: "操作失败" });
            });
        })
        .catch(() => {});
    },
    invitePerson(event, doctorInfo, doctorIndex, type) {
      this.config.doctors = [];
      if (this.requestLoading) return;
      let currIndex = this.inviteBanner.indexOf(true);
      if (currIndex >= 0) {
        if (currIndex === doctorIndex) {
          this.inviteBanner[doctorIndex] = false;
        }
        return;
      }
      this.inviteType = type;
      this.inviteBanner[doctorIndex] = true;

      let speakerItem = this.$refs[`speaker`].getBoundingClientRect();

      let inviteItem =
        this.$refs[`invite${doctorIndex}`]?.[0] ||
        this.$refs[`invite${doctorIndex}`];

      let item = inviteItem.getBoundingClientRect();
      let left = item.left - speakerItem.left;
      this.inviteStyle = `width:calc(${this.inviteWidth}px - 1rem);left:calc(-${left}px + 0.5rem);`;
      // this.loadDoctors("", doctorInfo);
      this.doctor = "";
    },
    changeDoctor() {},
    // 更换和邀请专家
    inviteDoctor(doctorInfo) {
      if (this.requestLoading) return;
      if (!this.doctor) {
        this.$tips.error({ text: "请选择专家" });
        return;
      }

      this.requestLoading = true;
      this.doInviteDoctor(doctorInfo.role, doctorInfo.index);
      // if (!doctorInfo.id && this.data.meet_type == 0) {
      //   this.requestLoading = true;
      //   this.doInviteDoctor(doctorInfo.role, doctorInfo.index);
      // } else {
      //   let doctor = this.config.doctors.find((x) => x.id == this.doctor);
      //   if (doctor.finish == 0) {
      //     this.$tips.error({
      //       text: "该专家暂未完善个人信息，请提醒专家登录系统完善",
      //     });
      //     return;
      //   }
      //   this.requestLoading = true;
      //   this.doUpdateDoctor(doctorInfo.role, doctorInfo.index);
      // }
    },
    doUpdateDoctor(role, index) {
      // 邀请专家
      let data = {
        id: this.data.id,
        user_id: this.doctor,
        role_code: role,
        index,
      };
      this.$axios
        .patch(this.$urls.live.updateDoctor, data)
        .then((res) => {
          this.inviteBanner = [];
          setTimeout(() => {
            this.updateAlreadyDoctor(role, index);
            this.requestLoading = false;
            this.$tips.success({
              text: "邀请已发送",
            });
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.requestLoading = false;
          }, 1000);
        });
    },
    doInviteDoctor(role, index) {
      // 邀请专家
      let data = {
        id: this.data.id,
        user_id: this.doctor,
        role_code: role,
        index,
      };
      let method, urlKey;
      if (this.inviteType == "add") {
        method = "post";
        urlKey = "invite";
      } else if (this.inviteType == "update") {
        method = "patch";
        urlKey = "inviteOther";
      } else if (this.inviteType == "replace") {
        method = "post";
        urlKey = "changeInvite";
      } else {
        return "错误的操作方式";
      }
      this.$axios[method](this.$urls.live[urlKey], data)
        .then((res) => {
          this.inviteBanner = [];
          if (this.inviteType != "replace") {
            this.updateDoctor(role, index);
          } else {
            this.updataDoctorExitStatus(role, index, 2);
          }
          setTimeout(() => {
            this.requestLoading = false;
            this.$tips.success({
              text: "邀请已发送",
            });
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.requestLoading = false;
          }, 1000);
        });
    },
    // 直接变更
    updateAlreadyDoctor(role, index) {
      let doctor = this.config.doctors.find((x) => x.id == this.doctor);
      let doctorInfo = {
        id: doctor.id,
        department: doctor.department,
        province: doctor.privince,
        city: doctor.city,
        name: doctor.name,
        hospital: doctor.hospital,
        job_name: doctor.job_name,
        role_code: role,
        role: role,
        index,
      };
      let doctorIndex = this.data[role].findIndex(
        (person) => person.index == index
      );
      if (doctorIndex > -1) this.data[role][doctorIndex] = doctorInfo;
      else this.data[role].push(doctorInfo);
      console.log(doctorIndex, this.data[role], index);
    },
    deleteAlreadyDoctor(role, index) {
      let rolePersonIndex = (this.data[role] || []).findIndex(
        (person) => person.index == index
      );
      this.data[role].splice(rolePersonIndex, 1);
    },
    updataDoctorExitStatus(role, index, status) {
      let rolePersonIndex = (this.data[role] || []).findIndex(
        (person) => person.index == index
      );
      this.data[role][rolePersonIndex].accept_join = status;
    },
    // 更新邀请
    updateDoctor(role, index) {
      let doctor = this.config.doctors.find((x) => x.id == this.doctor);
      let doctorInfo = {
        id: doctor.id,
        department: doctor.department,
        province: doctor.privince,
        city: doctor.city,
        name: doctor.name,
        hospital: doctor.hospital,
        job_name: doctor.job_name,
        role_code: role,
        role: role,
        index,
      };
      let doctorIndex = this.inviteRoles.findIndex(
        (person) => person.role == role && person.index == index
      );
      if (doctorIndex > -1) this.inviteRoles[doctorIndex] = doctorInfo;
      else this.inviteRoles.push(doctorInfo);
      // 取消已经被邀请人
      let rolePersonIndex = (this.data[role] || []).findIndex(
        (person) => person.index == index
      );
      if (rolePersonIndex > -1) {
        this.data[role].splice(rolePersonIndex, 1);
      }
    },
    // 同意之后更新
    updateAgreeDoctor(role, index) {
      let doctor = this.findInviteDoctor({ role, index });
      this.tempRoles[role] = this.tempRoles[role] || [];
      this.tempRoles[role].push(doctor);
    },
    deleteInviteDoctor(role, index) {
      let rolePersonIndex = this.inviteRoles.findIndex(
        (person) => person.role == role && person.index == index
      );
      if (rolePersonIndex > -1) {
        this.inviteRoles.splice(rolePersonIndex, 1);
      }
    },
    agreeInvite(role, index) {
      if (this.requestLoading) return;
      let currLoading = this.$tips.loading();
      let url = this.$tools.getURL(this.$urls.live.agree, { id: this.data.id });
      this.$axios
        .get(url)
        .then((res) => {
          this.updateAgreeDoctor(role, index);
          this.updateInviteCount();
          setTimeout(() => {
            this.requestLoading = false;
            currLoading.close();
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.requestLoading = false;
            currLoading.close();
          }, 1000);
        });
    },
    updateInviteCount() {
      let userInfo = this.$store.state.common.userInfo;
      userInfo.invite_count -= 1;
      userInfo.invite_count =
        userInfo.invite_count > 0 ? userInfo.invite_count : 0;
      this.$store.dispatch("setItem", { userInfo });
    },
    clearInviteBanner() {
      this.inviteBanner = [];
    },
    //我要参会  邀请回帖并加入
    geToMeet(role, index) {
      let userInfo = this.$store.state.common.userInfo;
      if (userInfo.work_card_ocr_status == 1) {
        if (
          (role == "ROLE_ZHUXI" &&
            userInfo.join_cohosts_count == userInfo.cohosts_count) ||
          (role == "ROLE_TAOLUN" &&
            userInfo.join_guest_count == userInfo.guest_count)
        ) {
          let msg = {
            title: "温馨提示",
            html: `<div class="popup-content-main">当前角色权益次数已用完</div>`,
            btngroup: { sure: "确认" },
            align: "center",
          };
          PopopInstance(msg).then((res) => {
            return;
          });

          return;
        }
        let roles = this.getRoles();
        let names = roles.filter((role) => role.name).map((role) => role.name);
        this.popupKey = "have";
        this.popupInfo = { role, index };
        if (role == "ROLE_ZHUXI") {
          this.config.msg[
            this.popupKey
          ].html = `<div class="popup-content-main">该会议预计开会时间为${
            this.data.meet_start_time
          } \n参会嘉宾有${names.join(",")} \n确认参会该会议吗?</div>`;
        } else {
          this.config.msg[
            this.popupKey
          ].html = `<div class="popup-content-main">该会议预计开会时间为${
            this.data.meet_start_time
          } \n参会嘉宾有${names.join(",")} \n确认参会该会议吗?</div>
          <div  class="colorRed" style="font-size:0.8rem">注：点击确认后，需提交评论，方可算正式报名</div>`;
        }
        PopopInstance(this.config.msg[this.popupKey])
          .then((res) => {
            this.doSure(role, index);
          })
          .catch(() => {
            console.log("关闭");
          });
      } else {
        this.popupKey = "not";
        if (userInfo.work_card_ocr_status == 0) {
          this.config.msg[
            this.popupKey
          ].html = `<div class="popup-content-main">您的职称资料正在审核中，审核通过后方可申请</div>`;
          this.config.msg[this.popupKey].btngroup.sure = "确定";
        }
        PopopInstance(this.config.msg[this.popupKey])
          .then(() => {
            if (userInfo.work_card_ocr_status == 0) return;
            this.doSure(role, index);
          })
          .catch(() => {
            console.log("关闭");
          });
        this.popupInfo = {};
      }
    },
    getBoxClass(doctorInfo, doctorIndex) {
      if (doctorIndex >= 2 && this.isFive()) {
        let doctor = this.findInviteDoctorZX(doctorInfo.role, doctorInfo.index);
        let moreClass = doctor ? "speaker_discuss_box_opera" : "";
        return `speaker_discuss_box ${moreClass}`;
      }
      return "speaker_box";
    },
    isFive() {
      return this.getRoles().length > 4;
    },
    goToUpdate() {
      this.$router.push({
        path: "/applyMeet",
        query: {
          id: this.data.id,
        },
      });
    },
    goToChat() {
      this.$router.push({
        path: "/chatRoom",
        query: {
          id: this.data.id,
          role: 1,
        },
      });
    },
    doUpdateMeetType() {
      PopopInstance(this.config.msg.mode)
        .then((res) => {
          if (this.requestLoading) return;
          this.requestLoading = true;
          let url = this.$tools.getURL(this.$urls.live.updateMeetMode, {
            id: this.item.id,
          });
          this.$axios
            .get(url)
            .then((res) => {
              setTimeout(() => {
                this.requestLoading = false;
              }, 1000);
              this.data.post_type = 0;
              this.$tips.success({ text: "转换成功" });
            })
            .catch((err) => {
              setTimeout(() => {
                this.requestLoading = false;
              }, 1000);
              console.log(err);
              this.$tips.error({ text: "操作失败" });
            });
        })
        .catch(() => {});
    },
    doRefuse(role, index) {
      PopopInstance(this.config.msg.refuse)
        .then((res) => {
          if (this.requestLoading) return;
          this.requestLoading = true;
          let url = this.$tools.getURL(this.$urls.live.refuse, {
            id: this.item.id,
          });
          this.$axios
            .get(url)
            .then((res) => {
              setTimeout(() => {
                this.requestLoading = false;
              }, 1000);
              this.deleteInviteDoctor(role, index);
              this.updateInviteCount();
              this.$tips.success({ text: "拒绝成功" });
            })
            .catch((err) => {
              setTimeout(() => {
                this.requestLoading = false;
              }, 1000);
              console.log(err);
              this.$tips.error({ text: "操作失败" });
            });
        })
        .catch(() => {});
    },
    doCommitAudit() {
      PopopInstance(this.config.msg.audit)
        .then((res) => {
          if (this.requestLoading) return;
          this.requestLoading = true;
          let url = this.$tools.getURL(this.$urls.live.submit, {
            id: this.item.id,
          });
          this.$axios
            .get(url)
            .then((res) => {
              setTimeout(() => {
                this.requestLoading = false;
              }, 1000);
              this.data.audit_status = 40;
              this.$tips.success({ text: "提交成功" });
            })
            .catch(() => {
              setTimeout(() => {
                this.requestLoading = false;
              }, 1000);
              this.$tips.success({ text: "提交失败" });
            });
        })
        .catch(() => {});
    },
    joinMeeting() {
      if (this.requestLoading) return;
      this.requestLoading = true;
      let url = this.$tools.getURL(this.$urls.live.join, {
        id: this.data.id,
        ...this.popupInfo,
        role_code: this.popupInfo.role,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.$tips.success({ text: "加入会议成功" });
          let doctorInfo = {
            id: this.$store.state.common.userInfo.id,
            department: this.$store.state.common.userInfo.department,
            province: this.$store.state.common.userInfo.privince,
            city: this.$store.state.common.userInfo.city,
            name: this.$store.state.common.userInfo.name,
            hospital: this.$store.state.common.userInfo.hospital,
            job_name: this.$store.state.common.userInfo.job_name,
            role_code: this.popupInfo.role,
            role: this.popupInfo.role,
            index: this.popupInfo.index,
          };
          let doctorIndex = this.data[this.popupInfo.role].findIndex(
            (doctor) => doctor.index == this.popupInfo.index
          );
          if (doctorIndex > 0) {
            this.data[this.popupInfo.role][doctorIndex] = doctorInfo;
          } else {
            this.tempRoles[this.popupInfo.role] = [];
            this.tempRoles[this.popupInfo.role].push(doctorInfo);
          }
          setTimeout(() => {
            this.requestLoading = false;
          }, 1000);
        })
        .catch((res) => {
          setTimeout(() => {
            this.requestLoading = false;
          }, 1000);
        });
    },
    doSure(role, index) {
      if (this.popupKey == "not") {
        this.$router.push({
          path: "/doctorInfo",
        });
      } else {
        if (this.popupInfo.role == this.$config.types.roles.zx) {
          this.joinMeeting(role, index);
        } else {
          this.goToDetail();
        }
      }
    },
    doClose() {
      this.showPopup = false;
    },
    goToAgreement(data, type, doctor_audit_status = 1) {
      if (type == 1) {
        this.$router.push({
          path: "/agreement",
          query: {
            meetId: data.id,
            user_id: this.$store.state.common.userId,
            type: 1,
          },
        });
      } else if (type == 2 || doctor_audit_status == 2) {
        this.$tips.error({
          text: "会议审核未通过",
        });
      } else {
        this.$tips.error({
          text: "劳务协议准备中",
        });
      }
    },
    //我的支付状态
    payStatsu(data, status) {
      console.log("object :>> ", status); //0 待支付 1 已支付 2 失败
      let msg = {
        title: "我的支付状态",
        html: `<div class="popup-content-main">已提交协议，基金会正在审核支付中。提交后预估30天到账，请留意短信及本平台微信推送。</div>`,
        btngroup: { sure: "确认" },
        align: "center",
      };
      if (status == 1) {
        msg.html = `<div class="popup-content-main">已支付\n支付时间：${data.doctor_pay_time}\n支付金额：${data.doctor_pay_money}\n支付方：${data.doctor_pay_name}</div>`;
      } else if (status == 2) {
        msg.html = `<div class="popup-content-main">支付失败\n反馈日期：${data.feed_back_time}\n失败原因：${data.feed_message_info}</div>`;
      }
      PopopInstance(msg).then(() => {});
    },
    //参与评选
    participateSelection(data) {
      let msg1 = {
        title: "评选活动公告",
        html: `<div class="popup-content-main">骨转百讯-肿瘤免疫临床实践热门话题# 评选、报道活动火热进行中，评选为优秀话题可获得【推医汇】公众号的特别报道，提升学术影响力。\n您参与的会议名为【${data.title}】的会议，审核通过后即可参与评选活动，快来参加吧!</div>`,
        btngroup: { sure: "我同意以上会议参与本期评选" },
        align: "center",
        info: "success",
      };
      let msg2 = {
        title: "评选活动公告",
        html: `<div class="popup-content-main">您已同意本场会议参与评选。待本场其他专家均同意且会议完成后，公众号会提醒您转发点赞</div>`,
        btngroup: { sure: "去评选区看看" },
        align: "center",
        info: "success",
      };
      if (data.is_selection == 0) {
        PopopInstance(msg1).then(() => {
          let Elloading = this.$tips.loading();
          let url = this.$tools.getURL(
            this.$urls.topicSelection.selection_check,
            {
              post_id: data.id,
            }
          );
          this.$axios.get(url).then(() => {
            data.is_selection = 1;
            Elloading.close();
            PopopInstance(msg2).then(() => {
              this.$router.push({
                path: "/topicSelectionList",
              });
            });
          });
        });
      } else {
        PopopInstance(msg2).then(() => {
          this.$router.push({
            path: "/topicSelectionList",
          });
        });
      }
    },
  },
};
</script>
<style 
>
.homeItem_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1rem;
  position: relative;
  font-size: 0.875rem;
  margin-bottom: 1rem !important;
  /* padding-top: 1.6rem; */
}

.homeItem_container .media {
  display: flex;
  height: 2.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

.homeItem_container .media_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
}

.homeItem_container .media img {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
}

.homeItem_container .media .head_name {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
  font-size: 0.7rem;
  border: 1px solid #e64638;
  border-radius: 2.5rem;
}

.homeItem_container .media .media_body_name {
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  width: 100%;
  white-space: nowrap;
}

.homeItem_container .media .media_body_hospital {
  font-size: 0.6rem;
  color: #8d93a1;
}

.homeItem_container .title {
  line-height: 1.3;
  margin: 0.5rem 0;
  font-size: 0.9rem;
  font-weight: 600;
}

.homeItem_container .info {
  color: #8d93a1;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.8rem;
}

.speaker_all {
  display: flex;
  flex-wrap: wrap;
}

.homeItem_container .speaker_box {
  width: calc(50% - 0.25rem);
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}
.homeItem_container .speaker_discuss_box {
  /* width: calc((100% - 1rem)/3); */
  flex: 1 0 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}
.speaker_discuss_box_opera {
  /* flex:1 0 0  !important; */
  flex: 1.2 0 0;
}
.homeItem_container .speaker_discuss_box:nth-child(4) {
  margin-right: 0;
}
.homeItem_container .speaker_discuss_box:nth-child(5) {
  margin-right: 0;
  margin-left: 0.5rem;
}
.homeItem_container .speaker_discuss_box:nth-child(5) .speaker_box_item {
  /* background: #eefc8a;  */
  /* border: 1px dashed deepskyblue;
  border-image: repeating-linear-gradient(
      135deg,
      #ccc,
      #ccc 5px,
      transparent 5px,
      transparent 10px
    )
    1; */
}

.homeItem_container .speaker_box:nth-child(2n) {
  margin-right: 0;
}

.homeItem_container .speaker_box_item {
  width: 100%;
  background: #f9fafb;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 3.25rem;
  /* position: relative; */
}
.speaker_box_item_name {
  white-space: nowrap;
}
.speaker_box_item_name span:first-child {
  background: #fff3f9;
  padding: 0.25rem0.5rem;
  border-radius: 0.25rem;
  font-size: 0.8rem;
}

.speaker_box_item_name span:nth-child(2) {
  margin-left: 0.5rem;
  /* font-size: 0.7rem; */
  font-size: 0.8rem;
}

.speaker_box_item_name_text {
  color: #000;
  margin-top: 0.5rem;
  /* font-size:0.1rem; */
}
.speaker_box_item_name_text_single {
  /* display: inline-block; */
  margin-top: 0.5rem;
}
.speaker_box_item_hospital_text {
  /* display: inline-block; */
  color: #8d93a1;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  /* line-height: 1.5rem; */
}
.speaker_box_item_hosptial {
  color: #8d93a1;
  font-size: 0.8rem;
  position: relative;
}

.homeItem_container .meet_date {
  margin-bottom: 0.5rem;

  font-size: 0.8rem;
}

.homeItem_container .meet_attachments {
  margin: 0.75rem 0;
  /* font-weight: bold; */
  font-size: 0.8rem;
  display: flex;
  /* align-items: center; */
}

.speaker_go_meeting {
  cursor: pointer;
  white-space: nowrap;
}
.meet_attachments .speaker_go_meeting {
  width: 4rem !important;
}
.home-item-invite-group .speaker_go_meeting {
  width: auto !important;
  padding: 0 0.5rem;
  white-space: nowrap;
}

.home-item-invite-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.meet_key {
  white-space: nowrap;
}

.homeItem_container .read {
  color: #8d93a1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}

.homeItem_container .disease_title_block {
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
  width: 100%;
}

.disease_title_type {
  display: flex;
  justify-content: flex-end !important;
  margin-top: 0 !important;
}
.homeItem_container .disease_title_block img {
  width: 1.5rem;
  height: 1.5rem;
}

.homeItem_container .disease_title {
  display: flex;
  align-items: center;
  background: #fff3f9;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
}

.disease_status {
  cursor: pointer;
}
.homeItem_container .disease_status {
  display: flex;
  align-items: center;
  background: #e64638;
  color: #fff;
  padding: 0.5rem 0.35rem;
  border-radius: 0.25rem;
  font-size: 0.7rem;
}

.speaker_go_meeting {
  height: 1.2rem;
  width: 60%;
  background: #e64638;
  color: #fff;
  font-size: 0.7rem;
  margin: 0;
  margin-top: 0.5rem;
  border-radius: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.speaker_go_meeting_disabled {
  background: #ccc;
}
.homeItem_status_block {
  font-size: 0.8rem;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 0;
}

.homeItem_status {
  background: #fff6f7;
  padding: 0.25rem 1rem;
  border-radius: 0 10px 0 10px;
  font-size: 0.9rem;
}
.homeItem_status_green {
  background: #22b783;
  color: #fff;
  padding: 0.25rem 1rem;
  border-radius: 0 10px 0 10px;
  font-size: 0.9rem;
}

.disease_groupbtn {
  justify-content: space-between;
}
.disease_groupbtn_end {
  justify-content: end !important;
}

.invite-person-main {
  /* width: calc(100% - 2rem); */
  height: auto;
  position: absolute;
  right: 0;
  top: 2.8rem;
  /* left:1rem; */
  /* background: red; */
  background: #fff;
  z-index: 1000;
  box-shadow: 0 0 0.2rem -0.1rem #222;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
}

.invite-person-select {
  border: 1px solid #e64638;
}

.invite-person-tip {
  padding: 1rem 0;
  color: #e64638;
  font-weight: 600;
}

.invite-person-button .speaker_go_meeting {
  width: 6rem;
}

.invite-person-tag-0::after {
  content: "";
  width: 0;
  height: 0;
  border-width: 0.5rem;
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  position: absolute;
  left: 2.75rem;
  top: -0.9rem;
  z-index: 1001;
}

.invite-person-tag-1::before {
  content: "";
  width: 0;
  height: 0;
  border-width: 0.5rem;
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  position: absolute;
  right: 7rem;
  top: -0.9rem;
  z-index: 1001;
}
.homeItem_container .chat_btn .el-badge {
  right: 1rem !important;
}
.homeItem_container .el-badge__content {
  right: 1.1rem !important;
}
.homeItem_container .item {
  margin-right: 10px;
}
.homeItem_container .el-input__inner {
  box-shadow: none !important;
}
.homeItem_container .el-select .el-input__wrapper,
.homeItem_container .el-select .el-input.is-focus .el-input__wrapper {
  box-shadow: none !important;
}
</style>